import React, { useState, useEffect, useRef } from "react";
import "./Dashboard.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isDateInPast } from "../../utils/isDateInPast";
import { usePostHog } from "posthog-js/react";
import clsx from "classnames";
import { useTranslation } from "react-i18next";
import { gameList } from "../../gameList";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Clock,
  CircleHelp,
  Plus,
  PcCase,
  Gamepad,
  Power,
  Check,
  Copy,
  Loader,
  CopyCheck,
  X,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Skeleton } from "@/components/ui/skeleton";
import AddPlaytimeDialog from "../../components/AddPlaytimeDialog/AddPlaytimeDialog";

const gamingRigStatusLink =
  "https://jly4ch5l57.execute-api.ap-south-1.amazonaws.com/default/gamingRigStatus";

const gamingRigStatusOptions = {
  client_loading: "client_loading",
  not_running: "not_running",
  running: "running",
  booting: "booting",
};

const isNewGameFlagSet = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const allowNewGame = urlParams.get("allowNewGame");
  return !!(allowNewGame && allowNewGame === "true");
};

const defaultSelectedGame = "Grand Theft Auto V Steam";

const Dashboard = ({
  user,
  userPlan,
  setUserPlan,
  paymentGateway,
  determineCountry,
}) => {
  const posthog = usePostHog();
  const allowNewGameFlag = useRef(isNewGameFlagSet()).current;
  const gameFromParams = new URLSearchParams(useLocation().search).get("game");
  const [state, setState] = useState({
    gamingRigStatus: gamingRigStatusOptions.client_loading,
    gamingRigIp: false,
    hoursRemaining: "Unknown",
    pin: "",
    pinUrl: "",
    pinSubmitted: false,
    areInstructionsExpanded: true,
    showCopiedTooltip: false,
    showPinLoader: false,
    showPinTick: false,
    gameName: gameFromParams || defaultSelectedGame,
    newGameName: "",
    newGameStore: "steam",
    gameSize: 0,
    loadingRigIsStartingStopping: false,
    cancellingBoot: false,
    hasShownRigStartNotification: false,
    allowNewGame: !!allowNewGameFlag,
    suggestedGame: "",
    isGameSuggested: false,
    showGameSuggestion: false,
    paymentPlanExpiryDate: "",
    startGameRequestError: false,
    allowTwoHoursForFifty: false,
    isHourlyPlan: true,
    accessAllGames: false,
    progress: 0,
    isYourOwnGamePlan: false,
    vm: false,
    vmDate: null,
    closestRegion: null,
    hasHours: false,
    startGamePleaseWait: false,
    region: "ap-south-1",
  });
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [purchaseHours, setPurchaseHours] = useState(20);
  const [checkoutEmail, setCheckoutEmail] = useState(null);
  const [sharedVms, setSharedVms] = useState([]);
  const [howToOpen, setHowToOpen] = React.useState(false);
  const [privatePcSelected, setPrivatePcSelected] = React.useState(
    gameFromParams ? false : true
  );

  const intervalRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const RAZORPAY_SCRIPT_URL = "https://checkout.razorpay.com/v1/checkout.js";
  const RAZORPAY_KEY = "rzp_live_u8YAfYrwkuCTLq";

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  async function handleHoursBuy(quantity, payment_gateway, isHourlyPlan) {
    setCheckoutLoading(true);
    await fetch(
      payment_gateway === "stripe"
        ? process.env.NODE_ENV === "development"
          ? "https://1ku3nifl2e.execute-api.ap-south-1.amazonaws.com/default/getStripeCheckout-dev-hello"
          : "https://v1wapno6ch.execute-api.ap-south-1.amazonaws.com/prod/getStripeCheckout-prod-hello"
        : process.env.NODE_ENV === "development"
        ? "https://9mljq6r4e3.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-dev-hello"
        : "https://d58cycm35h.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-prod-hello",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          payment_mode: "payment",
          products: [
            {
              priceId: null,
              isHourlyPlan: isHourlyPlan,
              adjustableQuantity: false,
              quantity: quantity,
              email: checkoutEmail,
              vmMachineType: null,
              vmPaymentPeriod: null,
              vmPaymentType: "payment",
              hoursPaymentPeriod: null,
              hoursPaymentType: "payment",
            },
          ],
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.checkout_url) {
          window.location.href = data.checkout_url;
        } else if (data.order_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id,
            amount: data.amount, // Amount should be in currency subunits
            currency: "INR",
            name: "GameAway",
            order_id: data.order_id,
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else if (data.subscription_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id, // Enter the Key ID generated from the Razorpay Dashboard
            subscription_id: data.subscription_id,
            name: "GameAway",
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
        setCheckoutLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCheckoutLoading(false);
      });
  }

  useEffect(() => {
    loadScript(RAZORPAY_SCRIPT_URL).then((loaded) => {
      if (!loaded) {
        console.log(
          "Failed to load Razorpay SDK. Please check your internet connection and try again."
        );
      }
    });
    const getSharedVms = async () => {
      const sharedVmJson = await fetch(
        "https://kxro7kkpf9.execute-api.ap-south-1.amazonaws.com/default/getVmSharedUsers",
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
        }
      );
      const sharedVms = await sharedVmJson.json();
      setSharedVms(sharedVms);
    };
    const requestNotificationPermission = async () => {
      if (Notification && Notification.requestPermission) {
        try {
          await Notification.requestPermission();
        } catch (err) {
          console.log("notification error", err);
        }
      }
    };
    const determineRegion = async () => {
      try {
        // Fetch the IP address
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        const ip = ipData.ip;

        // Fetch location data using the IP
        const locationResponse = await fetch(`https://ipinfo.io/${ip}/json`);
        const locationData = await locationResponse.json();

        const locations = {
          Mumbai: { lat: 19.076, lon: 72.8777 },
          Bahrain: { lat: 26.0667, lon: 50.5577 },
          "Hong Kong": { lat: 22.3193, lon: 114.1694 },
          Milan: { lat: 45.4642, lon: 9.19 },
        };

        const userLat = parseFloat(locationData.loc.split(",")[0]);
        const userLon = parseFloat(locationData.loc.split(",")[1]);

        let minDistance = Number.MAX_VALUE;
        let closestCity = "";
        Object.keys(locations).forEach((city) => {
          const distance = Math.sqrt(
            Math.pow(locations[city].lat - userLat, 2) +
              Math.pow(locations[city].lon - userLon, 2)
          );
          if (distance < minDistance) {
            minDistance = distance;
            closestCity = city;
          }
        });

        let closestRegion = "ap-south-1";
        if (closestCity === "Mumbai") {
          closestRegion = "ap-south-1";
        } else if (closestCity === "Bahrain") {
          closestRegion = "me-south-1";
        } else if (closestCity === "Hong Kong") {
          closestRegion = "ap-east-1";
        } else if (closestCity === "Milan") {
          closestRegion = "eu-south-1";
        }
        console.log("Closest region is: ", closestRegion);

        setState((prevState) => ({ ...prevState, region: closestRegion }));
      } catch (error) {
        console.error("Error fetching IP or location:", error);
      }
    };
    requestNotificationPermission();
    checkInstanceState();
    determineRegion();
    getSharedVms();
    determineCountry();

    return () => {
      if (
        state.gamingRigStatus !== gamingRigStatusOptions.booting &&
        state.gamingRigStatus !== gamingRigStatusOptions.client_loading
      ) {
        clearInterval(intervalRef.current);
        localStorage.removeItem("loaderStartTime");
      }
    };
  }, []);

  useEffect(() => {
    if (state.gamingRigStatus === gamingRigStatusOptions.booting) {
      if (!localStorage.getItem("loaderStartTime")) {
        localStorage.setItem("loaderStartTime", Date.now());
      }
      intervalRef.current = setInterval(() => {
        const elapsedTime =
          Date.now() - Number(localStorage.getItem("loaderStartTime"));
        const progress = Math.min((elapsedTime / (2 * 60 * 1000)) * 100, 100);
        setState((prevState) => ({ ...prevState, progress }));
        if (progress >= 100) {
          clearInterval(intervalRef.current);
          localStorage.removeItem("loaderStartTime");
        }
      }, 1000);
    } else if (
      state.gamingRigStatus !== gamingRigStatusOptions.client_loading
    ) {
      clearInterval(intervalRef.current);
      setState((prevState) => ({ ...prevState, progress: 0 }));
      localStorage.removeItem("loaderStartTime");
    }

    return () => clearInterval(intervalRef.current);
  }, [state.gamingRigStatus]);

  const startProgress = () => {
    const startTime = localStorage.getItem("loaderStartTime");
    intervalRef.current = setInterval(() => {
      const elapsedTime = Date.now() - Number(startTime);
      const progress = Math.min((elapsedTime / (4 * 60 * 1000)) * 100, 100);
      setState((prevState) => ({ ...prevState, progress }));
      if (progress >= 100) {
        clearInterval(intervalRef.current);
        localStorage.removeItem("loaderStartTime");
      }
    }, 1000);
  };

  const showNotification = (text) => {
    try {
      if (Notification && Notification.permission === "granted") {
        new Notification(text);
        setState((prevState) => ({
          ...prevState,
          hasShownRigStartNotification: true,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleIpCopy = (clipboardText) => {
    navigator.clipboard.writeText(clipboardText);
    setState((prevState) => ({ ...prevState, showCopiedTooltip: true }));
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, showCopiedTooltip: false }));
    }, 1500);
  };

  const handlePinChange = (value) => {
    setState((prevState) => ({ ...prevState, pin: value }));
  };

  const startGamingRig = async (gameName) => {
    setState((prevState) => ({
      ...prevState,
      gamingRigStatus: gamingRigStatusOptions.booting,
      loadingRigIsStartingStopping: true,
    }));

    let gameNameToSend =
      gameName || (allowNewGameFlag ? state.newGameName : state.gameName);
    let sharingVmUser = null;

    if (gameNameToSend === state.gameName && gameNameToSend.includes("@")) {
      sharingVmUser = gameNameToSend;
      gameNameToSend = "VM";
    }

    try {
      window.dataLayer.push({
        event: "start_gaming_rig",
        selectedGame: gameNameToSend,
      });
    } catch (err) {
      console.log(err, "Error in GTM code");
    }

    try {
      const startGameResponse = await fetch(
        "https://yx539fwm53.execute-api.ap-south-1.amazonaws.com/default/startGamingRig",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify({
            game: gameNameToSend,
            sharing_vm_user: sharingVmUser,
            game_size: state.gameSize,
            email: checkoutEmail,
            region: state.region,
            ...(allowNewGameFlag && { store: state.newGameStore }),
          }),
        }
      );

      if (startGameResponse.status === 401) {
        setState((prevState) => ({
          ...prevState,
          startGameRequestError: true,
        }));
      }

      if (startGameResponse.status === 404) {
        setState((prevState) => ({
          ...prevState,
          startGamePleaseWait: true,
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            startGamePleaseWait: false,
          }));
        }, 15000);
      }

      if (startGameResponse.status === 504) {
        setTimeout(checkInstanceState, 15000);
      }

      await checkInstanceState();
      setState((prevState) => ({
        ...prevState,
        loadingRigIsStartingStopping: false,
      }));

      posthog?.capture("start_game", {
        selectedGame: state.gameName,
        userEmail: user.email,
      });
    } catch (error) {
      await checkInstanceState();
      setTimeout(checkInstanceState, 10000);
      setState((prevState) => ({
        ...prevState,
        loadingRigIsStartingStopping: false,
      }));
    }
  };

  const shutdownGamingRig = async () => {
    setState((prevState) => ({
      ...prevState,
      gameName: defaultSelectedGame,
      loadingRigIsStartingStopping: true,
      cancellingBoot: true,
    }));
    await fetch(
      "https://b9f178yc79.execute-api.ap-south-1.amazonaws.com/default/shutdownGamingRig",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          region: state.region,
        }),
      }
    );
    await checkInstanceState();
    setState((prevState) => ({
      ...prevState,
      loadingRigIsStartingStopping: false,
      cancellingBoot: false,
    }));
    posthog?.capture("stop_game", {
      selectedGame: state.gameName,
      userEmail: user.email,
    });
  };

  const setPin = async () => {
    setState((prevState) => ({
      ...prevState,
      pinSubmitted: true,
      showPinLoader: true,
    }));
    try {
      await fetch(`${state.pinUrl}${state.pin}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      });
      setState((prevState) => ({
        ...prevState,
        pinSubmitted: false,
        showPinLoader: false,
        showPinTick: true,
      }));
    } catch (err) {
      console.log(err);
    }
    posthog?.capture("set_pin", {
      selectedGame: state.gameName,
      userEmail: user.email,
    });
  };

  const setPaymentPlanState = (gamingRigStatusJson) => {
    const { max_bitrate, hours_remaining, isHourlyPlan, vm, vmExpiry } =
      gamingRigStatusJson;
    const vmDate = new Date(vmExpiry * 1000);
    setState((prevState) => ({
      ...prevState,
      vmExpiry: vmDate,
      vm: vm && !isDateInPast(vmDate),
      hasHours: hours_remaining > 0.01,
    }));
    setPrivatePcSelected(!(hours_remaining > 0.01 && !vm));
  };

  const checkInstanceState = async () => {
    try {
      const gamingRigStatusResp = await fetch(gamingRigStatusLink, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
      });
      if (!gamingRigStatusResp.ok) {
        throw new Error(`HTTP error! status: ${gamingRigStatusResp.status}`);
      }
      const gamingRigStatusJson = await gamingRigStatusResp.json();
      const gamingRigStatusStr = gamingRigStatusJson.state;

      setPaymentPlanState(gamingRigStatusJson);

      if (gamingRigStatusStr === "not_running") {
        setState((prevState) => ({
          ...prevState,
          gamingRigStatus: gamingRigStatusOptions.not_running,
          hoursRemaining: gamingRigStatusJson.hours_remaining,
          hasShownRigStartNotification: false,
        }));
      } else if (gamingRigStatusStr === "booting") {
        setState((prevState) => ({
          ...prevState,
          gamingRigStatus: gamingRigStatusOptions.booting,
          hoursRemaining: gamingRigStatusJson.hours_remaining,
          gameName: gamingRigStatusJson.game_name,
          region: gamingRigStatusJson.region,
        }));
        setTimeout(() => {
          checkInstanceState();
        }, 10000);
      } else if (
        gamingRigStatusStr === "running" ||
        gamingRigStatusStr === "starting"
      ) {
        !state.hasShownRigStartNotification && showNotification("Game ready.");
        setState((prevState) => ({
          ...prevState,
          gamingRigStatus: gamingRigStatusOptions.running,
          gamingRigIp: gamingRigStatusJson.public_ip,
          pinUrl: gamingRigStatusJson.pin_url,
          hoursRemaining: gamingRigStatusJson.hours_remaining,
          gameName: gamingRigStatusJson.game_name,
          region: gamingRigStatusJson.region,
        }));
        setTimeout(() => {
          checkInstanceState();
        }, 120000);
      }
    } catch (err) {
      console.log("error in checkInstanceState", err);
    }
  };

  const handleGameChange = async (value) => {
    if (value === "VM" && state.closestRegion) {
      setState((prevState) => ({
        ...prevState,
        region: state.closestRegion,
        gameName: value,
        showGameSuggestion: value === "...request game",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        region: "ap-south-1",
        gameName: value,
        showGameSuggestion: value === "...request game",
      }));
    }
  };

  const handleRegionChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      region: value,
    }));
  };

  const startVirtualGamingPC = (event) => {
    startGamingRig("VM");
  };

  const gameValue = function getGameValue(game) {
    return game.store === "Epic" ? `${game.name} ${game.store}` : game.name;
  };

  const { userEmail } = user;
  const allowGameplay =
    state.gamingRigStatus === gamingRigStatusOptions.client_loading ||
    state.hasHours;

  const addHoursButton = (
    <div className="pt-4">
      <Link to="pricing" state={{ email: userEmail }}>
        <button className="btn btn-outline btn-accent w-full">
          {t("add_more_hours")}
        </button>
      </Link>
    </div>
  );

  const renderUpgradeButton = (
    <>
      <Link to="/pricing" className="w-full">
        <Button variant="default" asChild className="rounded-full w-full">
          <div>
            <div>
              {privatePcSelected
                ? "Upgrade to Private Gaming PC"
                : "Buy Quick Play PC"}
            </div>
          </div>
        </Button>
      </Link>
    </>
  );

  const renderPrivatePcBenefits = (
    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
            {t("cloud_local_saves")}
          </div>
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
            {t("install_your_games")}
          </div>
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
            {t("customizable_storage_space")}
          </div>
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
            {t("shareable_with_friends")}
          </div>
        </div>
      </div>
    </div>
  );

  const renderQuickPlayPcBenefits = (
    <div className="self-stretch flex-col justify-start items-start gap-4 flex">
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
            {t("pre_installed_and_shared")}
          </div>
        </div>
      </div>
      <div className="self-stretch justify-start items-start gap-3 inline-flex">
        <Check className="text-neutral-50 w-6 h-6 pl-[6.35px] pr-[5.65px] pt-[7.12px] pb-[6.38px] bg-green-600 rounded-full justify-center items-center flex overflow-hidden"></Check>
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
            {t("cloud_saves_only")}
          </div>
        </div>
      </div>
    </div>
  );

  const renderBootingState = (
    <>
      <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 h-10 justify-start items-center gap-4 flex">
          <div className="text-right text-neutral-50 text-sm font-medium leading-[14px]">
            IP
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <div className="self-stretch h-10 px-3 py-2 bg-[#1f242f] rounded-xl border border-[#333741] justify-start items-center gap-1 inline-flex overflow-hidden">
              <div className="grow shrink basis-0 text-neutral-50 text-sm font-normal leading-tight">
                Loading...
              </div>
            </div>
          </div>
        </div>
        <Button variant="secondary" disabled className="rounded-full w-fit">
          <div className="gap-2 inline-flex items-center">
            <Copy />
            <div>Copy</div>
          </div>
        </Button>
      </div>
      <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 h-10 justify-start items-center gap-4 flex">
          <div className="text-right text-neutral-50 text-sm font-medium leading-[14px]">
            PIN
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <div className="self-stretch h-10 px-3 py-2 bg-[#1f242f] rounded-xl border border-[#333741] justify-start items-center gap-1 inline-flex overflow-hidden">
              <div className="grow shrink basis-0 text-[#94969c] text-sm font-normal leading-tight">
                1234
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-2 opacity-50 bg-fuchsia-700 rounded-full justify-center items-center gap-2 flex">
          <div className="text-neutral-50 text-sm font-semibold capitalize leading-tight">
            Set PIN
          </div>
        </div>
      </div>
      <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
      <div className="self-stretch h-10 justify-start items-start gap-4 flex">
        <div className="self-stretch h-10 flex-col justify-start gap-2 flex w-full items-start">
          <div className="self-stretch justify-start items-center gap-1 inline-flex">
            <Loader className="text-neutral-50 animate-spin w-4 h-4 p-[1.33px] justify-center items-center flex overflow-hidden" />
            <div className="grow shrink basis-0 text-neutral-50 text-sm font-medium leading-[14px]">
              Booting...
            </div>
            {state.cancellingBoot ? (
              <div className="text-sm text-muted-foreground">Cancelling...</div>
            ) : (
              <Button
                variant="link"
                onClick={shutdownGamingRig}
                className="w-fit h-fit p-0 text-destructive"
              >
                Cancel
              </Button>
            )}
          </div>
          <div className="w-full h-2 bg-[#1f242f] rounded-full justify-start items-center inline-flex overflow-hidden">
            <div
              className="h-4 relative bg-cyan-500"
              style={{ width: `${state.progress}%` }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );

  const renderRunningState = (
    <>
      <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 h-10 justify-start items-center gap-4 flex">
          <div className="text-right text-neutral-50 text-sm font-medium leading-[14px]">
            IP
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
            <div className="self-stretch h-10 px-3 py-2 bg-[#1f242f] rounded-xl border border-[#333741] justify-start items-center gap-1 inline-flex overflow-hidden">
              <div className="grow shrink basis-0 text-neutral-50 text-sm font-normal leading-tight">
                {state.gamingRigIp}
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="secondary"
          className="rounded-full w-fit"
          onClick={() => handleIpCopy(formattedIp)}
        >
          <div className="gap-2 inline-flex items-center">
            {!state.showCopiedTooltip && <Copy />}
            {state.showCopiedTooltip && <CopyCheck />}
            <div>Copy</div>
          </div>
        </Button>
      </div>
      <div className="self-stretch justify-start items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 h-10 justify-start items-center gap-4 flex">
          <div className="text-right text-neutral-50 text-sm font-medium leading-[14px]">
            PIN
          </div>
          <Input
            type="number"
            placeholder="Enter PIN Here"
            onChangeCapture={(e) => handlePinChange(e.currentTarget.value)}
            className="bg-gray-900 border-[#333741] text-neutral-50 rounded-xl py-0"
          />
        </div>
        <Button
          variant="default"
          asChild
          className="rounded-full w-fit cursor-pointer"
          onClick={() => setPin()}
        >
          <div>
            {state.pinSubmitted && (
              <Loader className="text-neutral-50 animate-spin w-4 h-4 p-[1.33px] justify-center items-center flex overflow-hidden" />
            )}
            {state.showPinTick && (
              <Check className="text-neutral-50 w-4 h-4 p-[1.33px] justify-center items-center flex overflow-hidden" />
            )}
            <div>Set PIN</div>
          </div>
        </Button>
      </div>
      <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
      {state.loadingRigIsStartingStopping ? (
        <Button
          disabled
          variant="secondary"
          className="rounded-full w-full self-stretch justify-center items-center gap-2 flex flex-row cursor-pointer"
        >
          <div className="gap-2 inline-flex items-center">
            <Loader className="animate-spin" />
            <div>Powering Off...</div>
          </div>
        </Button>
      ) : (
        <Button
          onClick={shutdownGamingRig}
          disabled={state.loadingRigIsStartingStopping}
          variant="secondary"
          asChild
          className="rounded-full w-full self-stretch justify-center items-center gap-2 flex flex-row cursor-pointer"
        >
          <div>
            <Power />
            <div>Power Off</div>
          </div>
        </Button>
      )}
    </>
  );

  const renderValidPrivatePcState = (
    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
      <div className="self-stretch text-neutral-50 text-sm font-medium leading-[14px]">
        Server Location
      </div>
      <Select
        className="self-stretch h-10 px-3 py-2 bg-[#1f242f] rounded-xl border border-[#333741] justify-between items-center inline-flex overflow-hidden w-full"
        value={state.region}
        onValueChange={handleRegionChange}
      >
        <SelectTrigger className="rounded-xl border border-[#333741] bg-[#1f242f] grow shrink basis-0 text-neutral-50 text-sm font-normal leading-tight">
          <SelectValue placeholder="Select language" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="ap-south-1">🇮🇳 India</SelectItem>
          <SelectItem value="me-south-1">🇦🇪 Middle East</SelectItem>
          <SelectItem value="ap-east-1">🇻🇳 SE Asia</SelectItem>
          <SelectItem value="eu-south-1">🇹🇷 Turkey</SelectItem>
        </SelectContent>
      </Select>
      <div className="self-stretch text-[#94969c] text-sm font-normal leading-tight">
        Pick a location that’s closest to you
      </div>
      {state.startGamePleaseWait && (
        <span className="mr-2 text-error text-sm">
          {t("please_wait_storing_data")}
        </span>
      )}
    </div>
  );

  const renderPowerOnButton = (
    <Button
      onClick={
        privatePcSelected ? startVirtualGamingPC : () => startGamingRig()
      }
      variant="default"
      asChild
      className="rounded-full w-full self-stretch justify-center items-center gap-2 flex flex-row cursor-pointer"
    >
      <div>
        <Power />
        <div>Power On</div>
      </div>
    </Button>
  );

  const renderValidQuickPlayPcState = (
    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
      <div className="self-stretch text-neutral-50 text-sm font-medium leading-[14px]">
        Pick Your Game
      </div>
      <Select
        className="self-stretch h-10 px-3 py-2 bg-[#1f242f] rounded-xl border border-[#333741] justify-between items-center inline-flex overflow-hidden w-full"
        value={state.gameName}
        onValueChange={handleGameChange}
      >
        <SelectTrigger className="rounded-xl border border-[#333741] bg-[#1f242f] grow shrink basis-0 text-neutral-50 text-sm font-normal leading-tight">
          <SelectValue placeholder="Select game" />
        </SelectTrigger>
        <SelectContent>
          {[
            ...sharedVms.map((email) => (
              <SelectItem key={email} value={email}>
                {`${email}'s Virtual Gaming PC`}
              </SelectItem>
            )),
            ...gameList
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((game) => (
                <SelectItem key={game.name} value={gameValue(game)}>
                  {game.store === "Epic"
                    ? `${game.name} (${game.store})`
                    : game.name}
                </SelectItem>
              )),
          ]}
        </SelectContent>
      </Select>
      <div className="w-4 h-4 relative overflow-hidden"></div>
    </div>
  );

  const buyButtonOrPlanExpiryDate = addHoursButton;

  const expiredMessage = <p>Continue playing by purchasing more hours</p>;

  const renderMainCard = function () {
    if (state.gamingRigStatus === gamingRigStatusOptions.booting) {
      return renderBootingState;
    } else if (state.gamingRigStatus === gamingRigStatusOptions.running) {
      return renderRunningState;
    } else if (
      state.gamingRigStatus === gamingRigStatusOptions.client_loading
    ) {
      return (
        <>
          <div className="space-y-2 w-full">
            <Skeleton className="h-4 w-28" />
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-4 w-60" />
          </div>
          <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
          <Button disabled className="rounded-full w-full inline-flex">
            <div>
              <div>Loading...</div>
            </div>
          </Button>
        </>
      );
    } else {
      if (privatePcSelected) {
        if (state.vm) {
          return (
            <>
              {renderValidPrivatePcState}
              <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
              {renderPowerOnButton}
            </>
          );
        } else {
          return (
            <>
              {renderPrivatePcBenefits}
              <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
              {renderUpgradeButton}
            </>
          );
        }
      } else {
        if (state.hasHours) {
          return (
            <>
              {renderValidQuickPlayPcState}
              <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
              {renderPowerOnButton}
            </>
          );
        } else {
          return (
            <>
              {renderQuickPlayPcBenefits}
              <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
              {renderUpgradeButton}
            </>
          );
        }
      }
    }
  };

  const hours = Math.floor(state.hoursRemaining);
  const minutes = Math.floor((state.hoursRemaining * 60) % 60);

  let hoursRemainingStr = `${hours}h ${minutes}m`;

  if (state.hoursRemaining < 0) {
    hoursRemainingStr = "0h 0m";
  }

  const formattedIp = isIOS()
    ? `[::ffff:${state.gamingRigIp}]`
    : state.gamingRigIp;

  return (
    <div>
      <div className="pt-8 flex-col justify-start items-center gap-6 inline-flex w-full">
        <div className="self-stretch pb-6 flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch justify-start items-start gap-4 lg:flex-row flex flex-col">
            <div className="grow shrink basis-0 justify-start items-start gap-5 flex flex-col lg:flex-row">
              <div className="w-10 h-10 relative">
                <div className="w-10 h-10 left-[11.86px] top-[-10.49px] absolute origin-top-left rotate-[15deg] bg-fuchsia-700 rounded-lg"></div>
                <div className="w-10 h-10 p-2.5 left-0 top-0 absolute bg-white/50 rounded-lg backdrop-blur-lg justify-center items-center inline-flex">
                  <Clock className="text-neutral-50 w-5 h-5 relative flex-col justify-start items-start flex overflow-hidden" />
                </div>
              </div>
              <div>
                <div className="flex-col justify-start items-start inline-flex w-fit">
                  <div className="self-stretch text-neutral-50 text-3xl font-bold leading-9">
                    {state.gamingRigStatus ===
                    gamingRigStatusOptions.client_loading
                      ? "Loading..."
                      : hoursRemainingStr}
                  </div>
                  <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
                    {t("remaining")}
                  </div>
                </div>
                {state.vm && (
                  <div className="flex-col justify-start items-start inline-flex w-fit">
                    <div className="self-stretch text-neutral-50 text-3xl font-bold leading-9">
                      {state.gamingRigStatus ===
                      gamingRigStatusOptions.client_loading
                        ? ""
                        : "+ Private Gaming PC"}
                    </div>
                    <div className="self-end text-[#94969c] text-base font-normal leading-normal">
                      <div className="flex flex-col items-center">
                        {`until ${state.vmExpiry.toDateString()}`}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="justify-start items-center gap-3 flex">
              <Sheet open={howToOpen} onOpenChange={setHowToOpen}>
                <SheetTrigger asChild>
                  <Button
                    variant="secondary"
                    asChild
                    className="rounded-full w-fit cursor-pointer"
                    onClick={() => setHowToOpen(!howToOpen)}
                  >
                    <div>
                      <CircleHelp />
                      <div>{t("how_to_start")}</div>
                    </div>
                  </Button>
                </SheetTrigger>
                <SheetContent
                  side="right"
                  className="w-full lg:min-w-[512px] flex flex-col bg-[#0c111d] p-6 rounded-3xl rounded-bl-xl rounded-br-xl shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.04)] border-l border-r border-b border-t border-[#1f242f] mt-16 lg:mt-2 lg:mr-2 mb-2 gap-6 overflow-y-scroll"
                >
                  <div className="justify-between items-start inline-flex w-full">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                      <div className="self-stretch text-neutral-50 text-xl font-bold leading-7">
                        {t("how_to_start_playing")}
                      </div>
                      <div className="self-stretch text-[#94969c] text-sm font-normal leading-tight">
                        {t("follow_the_steps")}
                      </div>
                    </div>
                    <div
                      onClick={() => setHowToOpen(!howToOpen)}
                      className="bg-white/0 rounded-full justify-center items-center gap-2 flex cursor-pointer"
                    >
                      <X className="w-full h-full relative overflow-hidden text-neutral-50" />
                    </div>
                  </div>
                  <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                    <div className="relative w-full pb-[56.25%] overflow-hidden">
                      <iframe
                        className="absolute top-0 left-0 w-full h-full"
                        src="https://www.youtube-nocookie.com/embed/LeybtBHg0-M"
                        title="Get started with cloud gaming on Gameaway"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                      <div className="self-stretch pb-6 justify-center items-start gap-4 inline-flex">
                        <div className="w-10 h-10 p-2 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                          <div className="w-6 h-6 text-center text-neutral-50 text-lg font-bold leading-7">
                            1
                          </div>
                        </div>
                        <div className="grow shrink basis-0 pt-1.5 flex-col justify-start items-center gap-2 inline-flex">
                          <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                            Download Moonlight
                          </div>
                          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                            <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
                              {t("instruction_moonlight")}
                            </div>
                            <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
                              Choose your device below:
                            </div>
                            <div className="self-stretch justify-start items-start gap-2 flex flex-col lg:flex-row">
                              <div className="bg-white/0 rounded-full justify-center items-center gap-2 flex">
                                <div className="text-neutral-50 text-base font-semibold leading-normal">
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://github.com/moonlight-stream/moonlight-qt/releases/download/v6.1.0/MoonlightSetup-6.1.0.exe"
                                  >
                                    Windows
                                  </a>
                                </div>
                              </div>
                              <div className="bg-white/0 rounded-full justify-center items-center gap-2 flex">
                                <div className="text-neutral-50 text-base font-semibold leading-normal">
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://github.com/moonlight-stream/moonlight-qt/releases/download/v6.1.0/Moonlight-6.1.0.dmg"
                                  >
                                    MacOS
                                  </a>
                                </div>
                              </div>
                              <div className="bg-white/0 rounded-full justify-center items-center gap-2 flex">
                                <div className="text-neutral-50 text-base font-semibold leading-normal">
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.limelight"
                                  >
                                    Android
                                  </a>
                                </div>
                              </div>
                              <div className="bg-white/0 rounded-full justify-center items-center gap-2 flex">
                                <div className="text-neutral-50 text-base font-semibold leading-normal">
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://apps.apple.com/us/app/moonlight-game-streaming/id1000551566"
                                  >
                                    iOS
                                  </a>
                                </div>
                              </div>
                              <div className="bg-white/0 rounded-full justify-center items-center gap-2 flex">
                                <div className="text-neutral-50 text-base font-semibold leading-normal">
                                  <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=com.limelight"
                                  >
                                    Smart TV
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="self-stretch">
                              <span className="text-[#94969c] text-base font-normal leading-normal">
                                Or visit{" "}
                              </span>
                              <span className="text-neutral-50 text-base font-semibold leading-normal">
                                <a
                                  rel="noreferrer"
                                  target="_blank"
                                  href="https://moonlight-stream.org/#"
                                >
                                  Moonlight
                                </a>
                              </span>
                              <span className="text-[#94969c] text-base font-normal leading-normal">
                                {" "}
                                for more download options.
                              </span>
                            </div>
                            <div className="self-stretch">
                              <Popover modal="true">
                                <PopoverTrigger>
                                  <span className="text-neutral-50 font-normal leading-normal decoration-dotted underline cursor-help">
                                    Moonlight Setup Tips
                                  </span>
                                </PopoverTrigger>
                                <PopoverContent className="border-secondary">
                                  <div>
                                    <iframe
                                      className="w-full h-full"
                                      src="https://www.youtube-nocookie.com/embed/gO1sObaRA5A"
                                      title="Get started with cloud gaming on Gameaway"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                  </div>
                                </PopoverContent>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch pb-6 justify-center items-start gap-4 inline-flex">
                        <div className="w-10 h-10 p-2 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                          <div className="w-6 h-6 text-center text-neutral-50 text-lg font-bold leading-7">
                            2
                          </div>
                        </div>
                        <div className="grow shrink basis-0 pt-1.5 flex-col justify-start items-center gap-2 inline-flex">
                          <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                            Power On
                          </div>
                          <div className="self-stretch">
                            <span className="text-[#94969c] text-base font-normal leading-normal">
                              {t("instruction_power_on_1")}{" "}
                            </span>
                            <span className="text-neutral-50 text-base font-semibold leading-normal">
                              {t("instruction_power_on_2")}
                            </span>
                            <span className="text-[#94969c] text-base font-normal leading-normal">
                              .
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch pb-6 justify-center items-start gap-4 inline-flex">
                        <div className="w-10 h-10 p-2 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                          <div className="w-6 h-6 text-center text-neutral-50 text-lg font-bold leading-7">
                            3
                          </div>
                        </div>
                        <div className="grow shrink basis-0 pt-1.5 flex-col justify-start items-center gap-2 inline-flex">
                          <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                            Go to Moonlight
                          </div>
                          <div className="self-stretch">
                            <span className="text-[#94969c] text-base font-normal leading-normal">
                              {t("instruction_go_to_moonlight_1")}{" "}
                            </span>
                            <span className="text-neutral-50 text-base font-semibold leading-normal">
                              +
                            </span>
                            <span className="text-[#94969c] text-base font-normal leading-normal">
                              {" "}
                              {t("instruction_go_to_moonlight_2")}{" "}
                            </span>
                            <span className="text-neutral-50 text-base font-semibold leading-normal">
                              [::ffff:
                            </span>
                            <span className="text-[#94969c] text-base font-normal leading-normal">
                              x.x.x.x
                            </span>
                            <span className="text-neutral-50 text-base font-semibold leading-normal">
                              ]
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch pb-6 justify-center items-start gap-4 inline-flex">
                        <div className="w-10 h-10 p-2 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                          <div className="w-6 h-6 text-center text-neutral-50 text-lg font-bold leading-7">
                            4
                          </div>
                        </div>
                        <div className="grow shrink basis-0 pt-1.5 flex-col justify-start items-center gap-2 inline-flex">
                          <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                            Set your PIN
                          </div>
                          <div className="self-stretch">
                            <span className="text-[#94969c] text-base font-normal leading-normal">
                              {t("instruction_set_pin_1")}{" "}
                            </span>
                            <span className="text-neutral-50 text-base font-semibold leading-normal">
                              Set PIN
                            </span>
                            <span className="text-[#94969c] text-base font-normal leading-normal">
                              .
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch pb-6 justify-center items-start gap-4 inline-flex">
                        <div className="w-10 h-10 p-2 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                          <div className="w-6 h-6 text-center text-neutral-50 text-lg font-bold leading-7">
                            5
                          </div>
                        </div>
                        <div className="grow shrink basis-0 pt-1.5 flex-col justify-start items-start gap-2 inline-flex">
                          <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                            Go back to Moonlight
                          </div>
                          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                            <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
                              {t("instruction_back_to_moonlight")}
                            </div>
                            <div className="pl-1.5 pr-2 py-2 bg-[#2f2f2f] rounded-xl justify-start items-center gap-3 inline-flex overflow-hidden">
                              <img
                                className="w-[62px] h-[84.07px]"
                                src="./moonlight-before.png"
                                alt="before"
                              />
                              <img
                                className="w-[62px] h-[84.07px]"
                                src="./moonlight-after.png"
                                alt="after"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch pb-6 justify-center items-start gap-4 inline-flex">
                        <div className="w-10 h-10 p-2 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                          <div className="w-6 h-6 text-center text-neutral-50 text-lg font-bold leading-7">
                            6
                          </div>
                        </div>
                        <div className="grow shrink basis-0 pt-1.5 flex-col justify-start items-start gap-2 inline-flex">
                          <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                            Shut down
                          </div>
                          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                            <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
                              {t("instruction_shut_down")}
                            </div>
                            <div className="pl-1.5 pr-2 py-2 bg-[#2f2f2f] rounded-xl justify-start items-center gap-3 inline-flex overflow-hidden">
                              <img
                                className="h-[84.07px]"
                                src="./steam-cloud.png"
                                alt="steam cloud"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
              <AddPlaytimeDialog user={user} paymentGateway={paymentGateway} />
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 justify-start items-start gap-12 flex flex-col lg:flex-row w-full">
        <div className="justify-start items-start gap-4 flex flex-row lg:flex-col w-full lg:w-1/3 mx-auto">
          <Button
            variant="ghost"
            className={cn(
              !privatePcSelected
                ? "bg-neutral-50 bg-opacity-5"
                : "bg-[#1F242F]",
              "w-full h-full px-4 pt-4 pb-5 justify-start rounded-2xl items-start gap-3 hover:bg-[#1F242F]"
            )}
            disabled={
              state.gamingRigStatus === gamingRigStatusOptions.booting ||
              state.gamingRigStatus === gamingRigStatusOptions.running
            }
            onClick={() => setPrivatePcSelected(true)}
          >
            <div
              className={cn(
                !privatePcSelected ? "bg-gray-800" : "bg-fuchsia-700",
                "w-8 h-8 p-2 rounded-full justify-center items-center hidden lg:flex overflow-hidden"
              )}
            >
              <PcCase className="w-4 h-4 relative flex-col justify-start items-start flex overflow-hidden text-neutral-50" />
            </div>
            <div className="grow shrink pt-0.5 flex-col justify-start items-start gap-0.5 inline-flex text-center lg:text-left">
              <div className="hidden lg:block self-stretch text-[#f5f5f6] text-lg font-semibold leading-7">
                Private Gaming PC
              </div>
              <div className="block lg:hidden self-stretch text-[#f5f5f6] text-lg font-semibold leading-7">
                Private PC
              </div>
              <div className="self-stretch text-[#94969c] text-sm font-normal leading-tight hidden lg:block">
                Play your own games
              </div>
            </div>
          </Button>
          <Button
            variant="ghost"
            className={cn(
              privatePcSelected ? "bg-neutral-50 bg-opacity-5" : "bg-[#1F242F]",
              "w-full h-full px-4 pt-4 pb-5 justify-start rounded-2xl items-start gap-3 text-left hover:bg-[#1F242F]"
            )}
            disabled={
              state.gamingRigStatus === gamingRigStatusOptions.booting ||
              state.gamingRigStatus === gamingRigStatusOptions.running
            }
            onClick={() => setPrivatePcSelected(false)}
          >
            <div
              className={cn(
                privatePcSelected ? "bg-gray-800" : "bg-fuchsia-700",
                "w-8 h-8 p-2 rounded-full justify-center items-center hidden lg:flex overflow-hidden"
              )}
            >
              <Gamepad className="w-4 h-4 relative flex-col justify-start items-start flex overflow-hidden text-neutral-50" />
            </div>
            <div className="grow shrink basis-0 pt-0.5 flex-col justify-start items-start gap-0.5 inline-flex text-center lg:text-left">
              <div className="self-stretch text-[#f5f5f6] text-lg font-semibold leading-7">
                Quick Play PC
              </div>
              <div className="self-stretch text-[#94969c] text-sm font-normal leading-tight hidden lg:block">
                Play our pre-installed games
              </div>
              <div className="self-stretch text-[#94969c] text-sm font-normal leading-tight hidden lg:block">
                or on a friend's shared PC
              </div>
            </div>
          </Button>
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-8 inline-flex w-full lg:w-2/3">
          <div className="self-stretch p-5 bg-[#0c111d] rounded-3xl shadow-sm border border-[#1f242f] flex-col justify-start items-start gap-6 flex">
            {privatePcSelected ? (
              <div className="self-stretch justify-start items-start gap-4 inline-flex">
                <div className="w-12 h-12 relative rounded-3xl">
                  <div
                    className={cn(
                      state.gamingRigStatus ===
                        gamingRigStatusOptions.booting ||
                        state.gamingRigStatus === gamingRigStatusOptions.running
                        ? "from-fuchsia-900"
                        : "from-gray-700",
                      "w-12 h-12 items-center justify-center absolute bg-gradient-to-b to-black rounded-full"
                    )}
                  >
                    <div
                      className={cn(
                        state.gamingRigStatus ===
                          gamingRigStatusOptions.booting ||
                          state.gamingRigStatus ===
                            gamingRigStatusOptions.running
                          ? "from-fuchsia-950"
                          : "from-gray-800",
                        "bg-gradient-to-b to-black w-[46px] h-[46px] left-[1px] top-[1px] absolute rounded-full border-gradient-to-b border-transparent"
                      )}
                    ></div>
                  </div>
                  <div
                    className={cn(
                      state.gamingRigStatus ===
                        gamingRigStatusOptions.booting ||
                        state.gamingRigStatus === gamingRigStatusOptions.running
                        ? "bg-fuchsia-700"
                        : "bg-gray-700",
                      "w-8 h-8 left-[8px] top-[8px] absolute rounded-[99px] flex-col justify-center items-center inline-flex"
                    )}
                  >
                    <PcCase className="w-5 h-5 text-neutral-50"></PcCase>
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                  <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                    Private Gaming PC
                  </div>
                  <div className="self-stretch text-[#94969c] text-sm font-normal leading-tight">
                    {t("play_your_own_games")}
                  </div>
                </div>
              </div>
            ) : (
              <div className="self-stretch justify-start items-start gap-4 inline-flex">
                <div className="w-12 h-12 relative rounded-3xl">
                  <div
                    className={cn(
                      state.gamingRigStatus ===
                        gamingRigStatusOptions.booting ||
                        state.gamingRigStatus === gamingRigStatusOptions.running
                        ? "from-fuchsia-900"
                        : "from-gray-700",
                      "w-12 h-12 items-center justify-center absolute bg-gradient-to-b to-black rounded-full"
                    )}
                  >
                    <div
                      className={cn(
                        state.gamingRigStatus ===
                          gamingRigStatusOptions.booting ||
                          state.gamingRigStatus ===
                            gamingRigStatusOptions.running
                          ? "from-fuchsia-950"
                          : "from-gray-800",
                        "bg-gradient-to-b to-black w-[46px] h-[46px] left-[1px] top-[1px] absolute rounded-full border-gradient-to-b border-transparent"
                      )}
                    ></div>
                  </div>
                  <div
                    className={cn(
                      state.gamingRigStatus ===
                        gamingRigStatusOptions.booting ||
                        state.gamingRigStatus === gamingRigStatusOptions.running
                        ? "bg-fuchsia-700"
                        : "bg-gray-700",
                      "w-8 h-8 left-[8px] top-[8px] absolute rounded-[99px] flex-col justify-center items-center inline-flex"
                    )}
                  >
                    <Gamepad className="w-5 h-5 text-neutral-50"></Gamepad>
                  </div>
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-0.5 inline-flex">
                  <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                    Quick Play PC
                  </div>
                  <div className="self-stretch text-[#94969c] text-sm font-normal leading-tight">
                    {t("choose_from_preinstalled")}
                  </div>
                </div>
              </div>
            )}
            <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
            {renderMainCard()}
            {/*{state.vm ? (
              state.gamingRigStatus === gamingRigStatusOptions.booting ? (
                renderBootingState
              ) : state.gamingRigStatus === gamingRigStatusOptions.running ? (
                renderRunningState
              ) : (
                <>
                  {privatePcSelected
                    ? renderValidPrivatePcState
                    : renderValidQuickPlayPcState}
                  <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                  {allowGameplay ? renderPowerOnButton : renderUpgradeButton}
                </>
              )
            ) : (
              <>
                {" "}
                {state.gamingRigStatus ===
                  gamingRigStatusOptions.client_loading ? (
                  <>
                    <div className="space-y-2 w-full">
                      <Skeleton className="h-4 w-28" />
                      <Skeleton className="h-10 w-full" />
                      <Skeleton className="h-4 w-60" />
                    </div>
                  </>
                ) : privatePcSelected ? (
                  renderPrivatePcBenefits
                ) : (
                  renderQuickPlayPcBenefits
                )}
                <div className="h-[0.5px] w-full bg-[#1f242f]"></div>
                {state.gamingRigStatus ===
                  gamingRigStatusOptions.client_loading ? (
                  <Button disabled className="rounded-full w-full inline-flex">
                    <div>
                      <div>Loading...</div>
                    </div>
                  </Button>
                ) : (
                  renderUpgradeButton
                )}
              </>
            )}*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
